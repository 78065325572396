import React, { useState } from 'react';
import { Container, Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import { ResponsiveContainer, Tooltip, XAxis, BarChart, Bar, YAxis, CartesianGrid } from 'recharts';
import axios from 'axios';
import moment from 'moment';
import config from './config';

const colors = [ '#004c6d', '#35568c', '#6f5a9e', '#aa579f', '#dd558e', '#ff606e', '#ff7e45', '#ffa600' ];

function App() {

  const [token, setToken] = useState(null);

  const [request, setRequest] = useState('');
  const [strategy, setStrategy] = useState('0');
  const [mortality, setMortality] = useState('0');
  const [market, setMarket] = useState('0');

  const [data, setData] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState(new Set());
  const [mode, setMode] = useState('chart'); //table

  function handleSubmit() {
    getToken()
      .then(t => axios.post(
        `${config.API_URL}/v2/scores/debug?strategyIndex=${strategy}&mortalityIndex=${mortality}&marketIndex=${market}&output=json`,
        JSON.parse(request), { headers: { 'Authorization': `Bearer ${t.access_token}` } }
      ))
      .then(r => {
        const d = r.data;
        const keys = Object.keys(d[0] || {});
        keys.sort();
        setData({ keys, data: d });
      });
  }

  function getToken() {
    const expires_in = token && token.expires_in && moment(parseInt(token.expires_in));
  
    if (expires_in && expires_in.isAfter()) {
      return Promise.resolve(token);
    }
    else {
      const params = new URLSearchParams();
      params.append('grant_type', 'client_credentials');
      params.append('client_id', config.CLIENT_ID);
      params.append('client_secret', config.CLIENT_SECRET);

      return axios.post(`${config.API_URL}/connect/token`, params)
        .then(r => {
          const t = {
            access_token: r.data.access_token,
            expires_in: moment().add(r.data.expires_in - 60, 'seconds').valueOf()
          };
          setToken(t);
          return t;
        });
    }
  }

  return (
    <Container fluid className="d-flex" style={{flex: 1, flexDirection: 'column'}}>

      <Form className="d-flex py-3" style={{flex: 1, flexDirection: 'column'}} noValidate onSubmit={e => { e.preventDefault(); handleSubmit(); return false; }}>
        <Row style={{flex: 1}}>
          <Col xs={12} className="d-flex" style={{flex: 1}}>
            <Form.Group className="d-flex" style={{flex: 1, flexDirection: 'column'}}>
              <Form.Label>Request</Form.Label>
              <Form.Control as="textarea" style={{flex: 1}} rows={3} value={request} onChange={e => setRequest(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={4} style={{overflow: 'auto'}}>
            <Form.Group>
              <Form.Label>Strategy ({strategy})</Form.Label>
              <Form.Control type="range" custom min={0} max={3} value={strategy} onChange={e => setStrategy(e.target.value)} />
            </Form.Group>
          </Col>

          <Col xs={4}>
            <Form.Group>
              <Form.Label>Mortality ({mortality})</Form.Label>
              <Form.Control type="range" custom min={0} max={9} value={mortality} onChange={e => setMortality(e.target.value)} />
            </Form.Group>
          </Col>

          <Col xs={4}>
            <Form.Group>
              <Form.Label>Market ({market})</Form.Label>
              <Form.Control type="range" custom min="0" max="999" value={market} onChange={e => setMarket(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="auto">
            <Button type="submit">Submit</Button>
          </Col>
          {data && <Col xs="auto">
            <ButtonGroup>
              <Button variant={mode === 'chart' ? 'primary' : 'light'} onClick={() => setMode('chart')}>Chart</Button>
              <Button variant={mode === 'table' ? 'primary' : 'light'} onClick={() => setMode('table')}>Table</Button>
            </ButtonGroup>
          </Col>}
        </Row>
      </Form>

      {data && <Row style={{flex: 3}}>
        <Col xs={3}>
          <div className="px-2 py-1" style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto'}}>
            {data.keys.filter(k => k !== 'Year').map(k => <Form.Check
              key={k}
              id={`checkbox${k}`}
              custom
              type="checkbox"
              label={k}
              checked={selectedKeys.has(k)}
              onChange={e => {
                const newSet = new Set([...selectedKeys]);
                if (e.target.checked) {
                  newSet.add(k);
                  setSelectedKeys(newSet);
                }
                else {
                  newSet.delete(k);
                  setSelectedKeys(newSet);
                }
              }}
            />)}
          </div>
        </Col>
        <Col xs={9}>
          {mode === 'chart' && <div className="px-2 py-1" style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden'}}>
            <ResponsiveContainer minHeight={360}>
              <BarChart data={data.data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }} stackOffset="sign">
                <CartesianGrid vertical={false} stroke="#dddddd" />
                <XAxis dataKey="Year" tick={{fontSize: 11, fontFamily: 'sans-serif'}} />
                <YAxis scale="linear" tick={{fontSize: 11, fontFamily: 'sans-serif'}} axisLine={false} />
                <Tooltip />

                {[...data.keys.filter(k => selectedKeys.has(k))].map((s, i) =>
                  <Bar key={`i${i}`} dataKey={s} stackId="0" isAnimationActive={false}
                    fill={colors[i % colors.length]} stroke={colors[i % colors.length]}
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          </div>}

          {mode === 'table' && <div className="px-2 py-1" style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto'}}>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {['Year', ...data.keys.filter(k => selectedKeys.has(k))].map((s, i) => 
                    <th key={`header${i}`}>{s.split(':').map((w, j) => 
                      <span key={`word${i}${j}`}>{j > 0 ? <>:<wbr/></> : null}{w}</span>)}
                    </th>)}
                </tr>
              </thead>
              <tbody>
                {data.data.map((r, i) => <tr key={`row${i}`}>
                  {['Year', ...data.keys.filter(k => selectedKeys.has(k))].map((s, j) =>
                    <td key={`cell${i}${j}`}>{typeof(r[s]) === 'number' ? Math.round(r[s] * 10000) / 10000 : r[s]}</td>)}
                </tr>)}
              </tbody>
            </table>
          </div>}
        </Col>
      </Row>}

    </Container>
  );
}

export default App;
